import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import fetchApi from "../../../api/fetchApi";
import useDimensions from "../../../hooks/useDimensions";
import useRecentContractNumbers from "../../../hooks/useRecentContractNumbers";
import useUser from "../../../hooks/useUser";
import { ProductPackage } from "../../../models/Product";
import { Service } from "../../../models/Service";
import createPattern from "../../../utils/createPattern";
import formatCurrency from "../../../utils/formatCurrency";
import formatDate from "../../../utils/formatDate";
import getLocaleTranslate from "../../../utils/getLocaleTranslate";
import CenterContainer from "../../layout/container/centerContainer";
import HeaderCardService from "../../layout/header/headerCardService";
import Button from "../button/customButton";
import SelectFree from "../inputs/selectFree";
import Loading from "../loading";
import { isArray } from "lodash";
import { SUBSCRIPTION } from "../../../constants/constant";
import CustomInput from "../inputs/cutsomInput";

export interface StepperProps {
  step?: number;
  service: Service;
  handleNext: () => void;
  selectedPackage: ProductPackage | null;
  setSelectedPackage: React.Dispatch<
    React.SetStateAction<ProductPackage | null>
  >;
}

const StepFormOne = ({ service, handleNext }: StepperProps) => {
  const { isSmall, isMedium } = useDimensions();
  const { updateUser, user, clearData } = useUser();
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isManyBills, setIsManyBills] = useState(false);
  const [bills, setBills] = useState<any[]>([]);
  const { billNumber = undefined } = useParams();
  const { recentContractNumbers, addContractNumber } = useRecentContractNumbers(
    service?.serviceid as string
  );
  const { handleSubmit, control } = useForm();
  const isSubscriptionType = service?.type === SUBSCRIPTION;
  const serviceEndPoint = isSubscriptionType ? "subscription" : "bill";
  const paramsName = isSubscriptionType ? "customerNumber" : "serviceNumber";
  const MONEY_REGEX_FORMAT = new RegExp(/^\d+(\.\d{1,2})?$/);

  useEffect(() => {
    if (billNumber) {
      handleSubmit(onSubmit)();
    }
  }, []);

  const selectBill = (billSearchdata: any, amount?: string) => {
    clearData();

    updateUser({
      billService: {
        ...user?.billService,
        ...billSearchdata,
        ...(isSubscriptionType && { amountLocalCur: amount }),
      },
    });

    handleNext();
  };

  const onSubmit = async (data: any) => {
    setError("");
    setisLoading(true);
    fetchApi(serviceEndPoint)
      .get<any>(
        `?serviceid=${Number(service.serviceid)}&merchant=${
          service.merchant
        }&${paramsName}=${data.billNumber}`
      )
      .then((e) => {
        if (!isArray(e)) {
          setError(t("error_occured"));

          return;
        }

        if (e.length === 0) {
          setError(t("no_result_found"));

          return;
        }

        if (e.length === 1) {
          addContractNumber(data?.billService);

          selectBill(e.at(0), data.amount);

          return;
        }

        setBills(e);
        setIsManyBills(true);
      })
      .finally(() => setisLoading(false));
  };

  return (
    <CenterContainer>
      <Loading isLoading={isLoading} />
      {error && (
        <Alert severity="error" sx={{ margin: 5 }}>
          <AlertTitle>{t("error")}</AlertTitle>
          {error}
        </Alert>
      )}
      <Box
        display={isSmall ? "block" : "flex"}
        justifyContent="space-between"
        alignItems="flex-start"
        paddingX={isSmall ? 1 : 10}
        mt={isSmall ? 1 : 5}
        mb={10}
      >
        {!isSmall && isMedium && (
          <Box
            sx={{ width: isSmall ? "100%" : "50%", textAlign: "left" }}
            py={isSmall ? 0 : 5}
          >
            <Typography variant={isSmall ? "h5" : "h4"}>
              {t("steps")}
            </Typography>
            <Box my={isSmall ? 2 : 5} ml={2}>
              <Box sx={{ display: "flex", paddingBottom: 2 }}>
                <Chip label="1" color="primary" />
                <ListItemText sx={{ paddingLeft: 2 }}>
                  {isSubscriptionType
                    ? t("subscription_flow.step_1")
                    : t("stepper_1.step_1")}
                </ListItemText>
              </Box>
              <Box sx={{ display: "flex", paddingBottom: 2 }}>
                <Chip label="2" color="primary" />
                <ListItemText sx={{ paddingLeft: 2 }}>
                  {isSubscriptionType
                    ? t("subscription_flow.step_2")
                    : t("stepper_1.step_2")}
                </ListItemText>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Chip label="3" color="primary" />
                <ListItemText sx={{ paddingLeft: 2 }}>
                  {isSubscriptionType
                    ? t("subscription_flow.step_3")
                    : t("stepper_1.step_3")}
                </ListItemText>
              </Box>
            </Box>
          </Box>
        )}

        <Box
          mt={5}
          sx={{
            width: isSmall ? "100%" : "60%",
          }}
        >
          <HeaderCardService service={service} />
          {isManyBills ? (
            <Box>
              <Typography variant="h4" py={2} pt={5}>
                {t("stepper_1.list_of_bills")}
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "#EAECF0" }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "700" }}>
                        {t("stepper_1.bill_number")}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "700" }}>
                        {t("amount")}
                      </TableCell>
                      <TableCell align="center" sx={{ fontWeight: "700" }}>
                        {t("stepper_1.due_date")}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: "700" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bills.map((row: any, index: number) => (
                      <TableRow
                        key={row.billNumber}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.billNumber}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(row.amountLocalCur)}
                        </TableCell>
                        <TableCell align="right">
                          {formatDate(row.billDueDate)}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            onClick={() => selectBill(bills[index])}
                          >
                            {t("button.pay")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box my={isSmall ? 1 : 3} mt={isSmall ? 1 : 5}>
                {
                  <>
                    <Typography paddingY={3}>
                      {service?.hint && getLocaleTranslate(service.hint)}
                    </Typography>
                    <SelectFree
                      id={"account_number-id"}
                      data-testid="account_number-id"
                      control={control}
                      name="billNumber"
                      defaultValue={billNumber}
                      sx={{ width: "100%" }}
                      variant="outlined"
                      label={getLocaleTranslate(
                        service?.labelServiceNumber || null
                      )}
                      rules={{
                        required: t("errors.field_required"),
                        pattern: {
                          value: createPattern(service?.validationMask || "/"),
                          message: t("errors.invalid_phone_number"),
                        },
                      }}
                      placeholder={
                        service?.hint && getLocaleTranslate(service.hint)
                      }
                      options={recentContractNumbers}
                    />
                    {isSubscriptionType && (
                      <>
                        <br />
                        <CustomInput
                          data-testid={"amount"}
                          label={t("amount") + " (F CFA)"}
                          control={control}
                          name="amount"
                          sx={{ width: "100%", paddingBottom: 8 }}
                          variant="outlined"
                          rules={{
                            required: t("errors.field_required"),
                            pattern: {
                              value: MONEY_REGEX_FORMAT,
                              message:
                                "Invalid money amount. Only numbers and up to two decimal places are allowed.",
                            },
                          }}
                        />
                      </>
                    )}
                  </>
                }
              </Box>

              <Button
                variant="contained"
                isLoading={isLoading}
                disabled={isLoading}
                fullWidth
                size="large"
                onClick={handleSubmit(onSubmit)}
              >
                {isSubscriptionType ? t("button.continue") : t("button.search")}
              </Button>
            </form>
          )}
        </Box>
      </Box>
    </CenterContainer>
  );
};

export default StepFormOne;
